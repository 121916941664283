<script>
export default {
  metaInfo: {
    title: 'HoneyMoney mobile web-app', // 70 characters
    meta: [
      { name: 'description', content: 'How to use HoneyMoney on your mobile phone' }
    ]
  },
  components: {
    hm_zoom: require('@/components/hm_zoom.vue').default
  }
}
</script>

<template lang="pug">
.content
  h2.title.is-2 Mobile web-app

  p You can enter transactions on the go with the mobile web-app (Progressive Web Application).
    p.has-text-centered
      .columns.is-gapless.is-mobile
        //- https://app.shotbot.io/#/project
        .column
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/list.png')
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/form.png')
        .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='../assets/mobile/breakdown.png')
        .column
  .subtitle.is-4 How To Add HoneyMoney to Your Home Screen:
  ol
    li
      | Visit <a href="https://app.honeymoney.io">https://app.honeymoney.io</a> from your mobile web browser.
      br
      img(src="./images/mobile1.png")
    li
      | Add HoneyMoney to your home screen.
      br
      img(src="./images/mobile2.png")
</template>
